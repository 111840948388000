export default {
  name: "accordion",
  component() {
    return {
      items: [],
      allowMultiple: false,
      init() {},
      initialise(allowMultiple = true) {
        this.allowMultiple = allowMultiple;
        this.items = [...this.$el.querySelectorAll(".accordion-item")].map(
          (item) => ({
            header: item.querySelector(".accordion-header"),
            content: item.querySelector(".accordion-content"),
            icon: item.querySelector("svg"),
            open: false,
          }),
        );
        this.items.forEach((item) => {
          item.header.addEventListener("click", () => {
            this.toggle(item);
          });
        });
      },
      toggle(item) {
        console.log("Accordion", "Toggle action");
        if (!this.allowMultiple) {
          this.items.forEach((i) => {
            if (i !== item && i.open) {
              this.close(i);
            }
          });
        }
        item.open = !item.open;
        item.open ? this.open(item) : this.close(item);
      },
      open(item) {
        console.log("Accordion", "Opening accordion");
        item.content.style.maxHeight = item.content.scrollHeight + "px";
        item.content.classList.add("open");
        console.log("Accordion", item.content);
        item.icon.classList.add("rotate-180");
      },
      close(item) {
        console.log("Accordion", "Closing accordion");
        item.content.style.maxHeight = null;
        item.content.classList.remove("open");
        item.icon.classList.remove("rotate-180");
        item.open = false;
      },
    };
  },
};
