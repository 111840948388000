
export const sizeselector = {
    name: 'sizeselector',
    component() {
        return {
            openSelector: false,
            init() {
                window.addEventListener("open-size-selector", this.open.bind(this));
                window.addEventListener("close-size-selector", this.close.bind(this));

            },
            open() {
                console.log('open');
                this.openSelector = true;
                // this.$refs.sizeselector.classList.add('open');
            },
            close() {
                console.log('closing size picker');
                this.openSelector = false;
                // this.$refs.sizeselector.classList.add('close');
            },

        }
    }
}

export default sizeselector;
