import { debounce } from "alpinejs/src/utils/debounce";

export default {
  name: "modelview",
  store() {
    return {
      isModelView: Alpine.$persist(false).as("isModelView"),
      applyGlobally: false, // New boolean for global application
      init() {
        this.checkUrl(); // Check URL on initialization
        this.dispatchEvent();
        // Add event listener for modelview-toggle
        window.addEventListener(
          "modelview-toggle",
          this.setModelView.bind(this),
        );
      },
      checkUrl() {
        // Reset isModelView to false if not global and URL does not contain '/collections/'
        if (
          !this.applyGlobally &&
          !window.location.pathname.includes("/collections/")
        ) {
          this.isModelView = false;
          this.dispatchEvent(); // Trigger the event to reflect the change
        }
      },
      setModelView(event) {
        this.isModelView = event.detail.isModelView;
        this.dispatchEvent();
      },
      toggleModelView: debounce(function () {
        this.isModelView = !this.isModelView;
        this.dispatchEvent();
      }, 300),
      dispatchEvent() {
        window.dispatchEvent(
          new CustomEvent("modelview-toggled", {
            detail: { isModelView: this.isModelView },
          }),
        );
      },
    };
  },
};
