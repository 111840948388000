
export const global = {
    name: 'global',
    store() {
    return {
      isMobileMenuVisible: false,
      isMinicartVisible: false,
      isPredictiveSearchVisible: false,
      isWindowScrolled: false,
      cart: null,
        currencyManager: null,
      init() {

        window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))
        window.addEventListener('keydown', this.onKeyDownHandler.bind(this))
        window.addEventListener('mobile-menu-open', this.openMobileMenu)
        window.addEventListener('mobile-menu-closed', this.closeMobileMenu)

        this.initLiquidAJaxCart()
      },
      get bodyClasses() {
        let classes = []

        if (this.isMobileMenuVisible) {
          classes.push('mobile-menu-visible')
        }
          if (this.isMinicartVisible) {
              classes.push('minicart-open')
          }
          if (this.isPredictiveSearchVisible) {
              classes.push('search-open')
          }

        return classes || ''
      },
      openMobileMenu() {
        this.isMobileMenuVisible = true
          document.body.classList[this.isMobileMenuVisible ? 'add' : 'remove']('mobile-menu-visible')
          document.body.classList[this.isMobileMenuVisible ? 'add' : 'remove']('no-scroll__menu')
      },
      closeMobileMenu() {
        this.isMobileMenuVisible = false
          document.body.classList[this.isMobileMenuVisible ? 'add' : 'remove']('mobile-menu-visible')
          document.body.classList[this.isMobileMenuVisible ? 'add' : 'remove']('no-scroll__menu')
      },
      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible
      },
      toggleSearch() {
        this.isPredictiveSearchVisible = !this.isPredictiveSearchVisible
        document.body.classList[this.isPredictiveSearchVisible ? 'add' : 'remove']('no-scroll__search')
        setTimeout(function(){document.querySelector('input[type="search"]').focus()},10);
      },
      toggleMinicart() {
        this.isMinicartVisible = !this.isMinicartVisible
        document.body.classList[this.isMinicartVisible ? 'add' : 'remove']('no-scroll')
      },
      initLiquidAJaxCart() {
        document.addEventListener("liquid-ajax-cart:request-end", (event) => {
          const { requestState, cart, previousCart, sections } = event.detail;

          if (requestState.requestType === 'add') {
            if (requestState.responseData?.ok) {
              this.isMinicartVisible = true
              document.body.classList.add('no-scroll');
            }
          }

          this.cart = cart
        });
      },
      onWindowScrollHandler() {
        const isScrolled = window.scrollY > 0

        this.isWindowScrolled = isScrolled
        document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
      },
        onKeyDownHandler(event) {
            if (event.key === 'Escape') {
                this.isMinicartVisible = false;
                this.isPredictiveSearchVisible = false;
            }
        },
    }
  }
}
export default global;
