export const blogfilters = {
  name: "blogfilters",
  component() {
    return {
      selectedTags: [],
      init() {
      },
      setTag(tag) {
        if (tag == "All") {
          this.selectedTags = [];
          this.dispatchFiltersUpdatedEvent();
          return;
        }
        if (this.selectedTags.includes(tag)) {
          this.selectedTags = this.selectedTags.filter((t) => t !== tag);
        } else {
          this.selectedTags.push(tag);
        }
        this.dispatchFiltersUpdatedEvent();
      },
      isTagSelected(tag) {
        return (
          this.selectedTags.length === 0 || this.selectedTags.includes(tag)
        );
      },
      dispatchFiltersUpdatedEvent() {
        const event = new CustomEvent("blog-filters-updated", {
          detail: { selectedTags: this.selectedTags },
        });
        window.dispatchEvent(event);
      },
    };
  },
};

export default blogfilters;
